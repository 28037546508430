export default class SalesReportSortService {
  sortTotal(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.total);
    const totalB = parseFloat(b.total);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }
  sortSubTotal(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.subtotal);
    const totalB = parseFloat(b.subtotal);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }

  sortGross(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.grossSale);
    const totalB = parseFloat(b.grossSale);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }

  sortDiscount(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.discountAmount);
    const totalB = parseFloat(b.discountAmount);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }

  sortTax(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.tax);
    const totalB = parseFloat(b.tax);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }
}
