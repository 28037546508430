import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "main-panel mt-lg-3" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "container-fluid-reports" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-8" }
const _hoisted_7 = { style: {"text-align":"center"} }
const _hoisted_8 = {
  key: 0,
  id: "report-title",
  class: "header-text"
}
const _hoisted_9 = {
  key: 1,
  id: "report-title",
  class: "header-text"
}
const _hoisted_10 = {
  key: 2,
  id: "report-title",
  class: "header-text"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Spinner = _resolveComponent("Spinner")
  const _component_PageHeader = _resolveComponent("PageHeader")
  const _component_Navbar = _resolveComponent("Navbar")
  const _component_SalesReport = _resolveComponent("SalesReport")
  const _component_TaxReport = _resolveComponent("TaxReport")
  const _component_ProductSalesReport = _resolveComponent("ProductSalesReport")
  const _component_PageFooter = _resolveComponent("PageFooter")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_PageHeader),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Navbar, { name: "Reports" }),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", null, [
            _createVNode("div", _hoisted_4, [
              _createVNode("div", _hoisted_5, [
                _createVNode("div", _hoisted_6, [
                  _createVNode("button", {
                    type: "button",
                    style: {"margin-right":"1px"},
                    class: "btn btn-primary",
                    onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.salesMode(...args)))
                  }, " Sales Report "),
                  _createVNode("button", {
                    type: "button",
                    class: "btn btn-primary",
                    style: {"margin-right":"1px"},
                    onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.taxMode(...args)))
                  }, " Tax Report "),
                  _createVNode("button", {
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.productSalesMode(...args)))
                  }, " Top Selling Items ")
                ])
              ]),
              _createVNode("div", _hoisted_7, [
                (_ctx.isSales)
                  ? (_openBlock(), _createBlock("h3", _hoisted_8, " Sales Report "))
                  : _createCommentVNode("", true),
                (_ctx.isTax)
                  ? (_openBlock(), _createBlock("h3", _hoisted_9, " Tax Report "))
                  : _createCommentVNode("", true),
                (_ctx.isProductSales)
                  ? (_openBlock(), _createBlock("h3", _hoisted_10, " Top Selling Items "))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSales)
                ? (_openBlock(), _createBlock("div", _hoisted_11, [
                    _createVNode(_component_SalesReport)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isTax)
                ? (_openBlock(), _createBlock("div", _hoisted_12, [
                    _createVNode(_component_TaxReport)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isProductSales)
                ? (_openBlock(), _createBlock("div", _hoisted_13, [
                    _createVNode(_component_ProductSalesReport)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createVNode(_component_PageFooter)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}