
import SalesReport from '@/components/SalesReport.vue';
import ProductSalesReport from '@/components/ProductSalesReport.vue';
import TaxReport from '@/components/TaxReport.vue';
import { defineComponent } from 'vue';

interface ComponentData {
  isSales: boolean;
  isTax: boolean;
  isProductSales: boolean;
}

export default defineComponent({
  name: 'Reports',
  components: {
    SalesReport,
    TaxReport,
    ProductSalesReport,
  },
  data() {
    const data: ComponentData = {
      isSales: true,
      isTax: false,
      isProductSales: false,
    };
    return data;
  },
  mounted() {
    this.isSales = true;
    this.isTax = false;
    this.isProductSales = false;
  },
  methods: {
    salesMode() {
      this.isSales = true;
      this.isTax = false;
      this.isProductSales = false;
    },

    taxMode() {
      this.isSales = false;
      this.isTax = true;
      this.isProductSales = false;
    },

    productSalesMode() {
      this.isSales = false;
      this.isTax = false;
      this.isProductSales = true;
    },
  },
});
