import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_apexchart = _resolveComponent("apexchart")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_apexchart, {
      width: "800",
      height: "500",
      type: "bar",
      options: _ctx.chartOptions,
      series: _ctx.series
    }, null, 8, ["options", "series"])
  ]))
}