
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BarChart',
  props: ['chartData'],
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'chart1',
          animations: {
            speed: 2000,
          },
          offsetX: -150,
          offsetY: 20,
          height: '100%',
          parentHeightOffset: 15,
          redrawOnParentResize: true,
        },
        colors: [
          '#98AFC7',
          '#566D7E',
          '#000080',
          '#3090C7',
          '#667C26',
          '#54C571',
          '#FFF380',
          '#FBB117',
          '#C88141',
          '#827839',
          '#6F4E37',
          '#F87217',
          '#E55451',
          '#9F000F',
          '#7D0552',
          '#F6358A',
          '#4E387E',
          '#E18B6B',
          '#B87333',
          '#008080',
        ],
        noData: {
          text: 'No Data Available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#d64123',
            fontSize: '20px',
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
        },
        tooltip: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
          },
        },
        xaxis: {
          categories: this.chartData.labels,
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: true,
            hideOverlappingLabels: false,
          },
        },
        legend: {
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          offsetX: 0,
          offsetY: 0,
          markers: {
            width: 15,
            height: 15,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 150,
            vertical: 0,
          },
        },
      };
    },
    series() {
      return [{ data: this.chartData.datasets[0].data, name: 'Sold' }];
    },
  },
});
