import Order from '@/Interfaces/Order';
import { TransactionType } from '@/enums/TransactionType';
import { PaymentType } from '@/enums/PaymentType';
import moment, { Moment } from 'moment';

export default class TaxReportSearchService {
  searchAndFilteredTaxes(taxes: any[], searchTerm: string) {
    if (searchTerm.length > 0) {
      const search = new RegExp(searchTerm, 'gi');
      taxes = taxes.filter((taxes) => {
        return (
          taxes.name.match(search) ||
          taxes.taxableAmount.toString().match(search) ||
          taxes.taxCollected.toString().match(search)
        );
      });
    }
    return taxes;
  }
}
