import { Money } from 'ts-money';
import { MoneyFactory } from './MoneyFactory';
import Order from '@/Interfaces/Order';
import ProductSalesReports from '@/Interfaces/ProductSalesReports';
import { SalesReportSummary } from '../Interfaces/SalesReportSummary';
import Tax from '@/Interfaces/Tax';
import VariantSnapshot from '@/Interfaces/VariantSnapshot';
import { TaxReportSummary } from '@/Interfaces/TaxReportSummary';
import { formatCurrency } from '@/helper/validations';

export default class ReportCalculationService {
  private moneyFactory: MoneyFactory;

  public constructor() {
    this.moneyFactory = new MoneyFactory();
  }

  public calculateSalesReportSummary(orders: Order[]): SalesReportSummary {
    const results = new SalesReportSummary();

    let transactionCount: number;
    let totalCollected: Money;
    let netSales: Money;
    let totalGrossSales: Money;
    let totalDiscounts: Money;
    let totalTaxs: Money;
    let totalWholeSales: Money;
    let totalProfit: number;

    transactionCount = orders.length;

    const collectedValues = orders.map((order) =>
      this.moneyFactory.toMoney(order.total),
    );
    totalCollected = collectedValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const netSaleValues = orders.map((order) =>
      this.moneyFactory.toMoney(order.subtotal),
    );
    netSales = netSaleValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const grossSaleValues = orders.map((order) =>
      this.moneyFactory.toMoney(order.grossSale),
    );
    totalGrossSales = grossSaleValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const discountsValues = orders.map((order) =>
      this.moneyFactory.toMoney(order.discountAmount),
    );
    totalDiscounts = discountsValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const taxValues = orders.map((order) =>
      this.moneyFactory.toMoney(order.tax),
    );
    totalTaxs = taxValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const totalWholeSalesValues = orders.map((order) => {
      if (order.variantTotalWholesalePrice) {
        const amount = formatCurrency(order.variantTotalWholesalePrice).replace(
          /\$|,/g,
          '',
        );
        return this.moneyFactory.toMoney(Number(amount));
      } else {
        return this.moneyFactory.toMoney(0);
      }
    });
    totalWholeSales = totalWholeSalesValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const profitValues = orders.map((order) => Number(order.profit));
    totalProfit = profitValues.reduce((acc, val) => acc + val);

    results.transactionCount = transactionCount;
    results.totalCollected = this.moneyFactory.toDecimal(totalCollected);
    results.netSales = this.moneyFactory.toDecimal(netSales);
    results.totalGrossSales = this.moneyFactory.toDecimal(totalGrossSales);
    results.totalDiscounts = this.moneyFactory.toDecimal(totalDiscounts);
    results.totalTaxs = this.moneyFactory.toDecimal(totalTaxs);
    results.totalWholeSales = this.moneyFactory.toDecimal(totalWholeSales);
    results.totalProfit = totalProfit;

    return results;
  }

  public calculateProductSalesReportSummary(
    orders: ProductSalesReports[],
  ): SalesReportSummary {
    const results = new SalesReportSummary();

    let transactionCount: number;
    let totalCollected: Money;
    let netSales: Money;

    transactionCount = orders.length;

    const collectedValues = orders.map((order) =>
      this.moneyFactory.toMoney(Number(order.Total)),
    );

    totalCollected = collectedValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    const netSaleValues = orders.map((order) =>
      this.moneyFactory.toMoney(Number(order.NetSell)),
    );
    netSales = netSaleValues.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );

    results.transactionCount = transactionCount;
    results.totalCollected = this.moneyFactory.toDecimal(totalCollected);
    results.netSales = this.moneyFactory.toDecimal(netSales);

    return results;
  }

  public calculateTaxReportSummary(orders: Order[]): TaxReportSummary {
    const results = new TaxReportSummary();

    let taxableSales: Money;
    let nonTaxableSales: Money;
    let totalNetSales: Money;

    let allSnapshots: VariantSnapshot[] = [];
    orders.forEach((order) => {
      allSnapshots = allSnapshots.concat(order.variantSnapshots);
    });

    const taxableSnapshots = allSnapshots.filter(
      (snapshot) => snapshot.taxable === true,
    );
    const nonTaxableSnapshots = allSnapshots.filter(
      (snapshot) => snapshot.taxable === false,
    );

    taxableSales = taxableSnapshots
      .map((snapshot) =>
        this.moneyFactory.toMoney(snapshot.variantDiscountedPrice),
      )
      .reduce(
        (acc, val) => acc.add(val),
        new Money(0, this.moneyFactory.getCurrency()),
      );

    nonTaxableSales = nonTaxableSnapshots
      .map((snapshot) =>
        this.moneyFactory.toMoney(snapshot.variantDiscountedPrice),
      )
      .reduce(
        (acc, val) => acc.add(val),
        new Money(0, this.moneyFactory.getCurrency()),
      );

    totalNetSales = taxableSales.add(nonTaxableSales);

    results.taxableSales = this.moneyFactory.toDecimal(taxableSales);
    results.nonTaxableSales = this.moneyFactory.toDecimal(nonTaxableSales);
    results.totalNetSales = this.moneyFactory.toDecimal(totalNetSales);

    return results;
  }

  // Individial Tax Calcs

  public calculateTaxableAmountForTax(tax: Tax) {
    const snapshots: VariantSnapshot[] = tax.variantSnapshotTaxes.map(
      (snapshotTax: any) => snapshotTax.variantSnapshot,
    );

    const snapshotSubtotals = snapshots.map((snapshot) =>
      this.moneyFactory.toMoney(snapshot.variantDiscountedPrice),
    );
    return snapshotSubtotals.reduce(
      (acc, val) => acc.add(val),
      new Money(0, this.moneyFactory.getCurrency()),
    );
  }

  public calculateTaxCollectedForTax(tax: Tax) {
    const variantSnapshotTaxes = tax.variantSnapshotTaxes!;

    return variantSnapshotTaxes.reduce(
      (acc: Money, val: any) =>
        acc.add(this.moneyFactory.toMoney(val.taxedAmount)),
      new Money(0, this.moneyFactory.getCurrency()),
    );
  }

  public filterObjects(
    objects: any[],
    dateFilterFrom: string,
    dateFilterTo: string,
  ) {
    if (dateFilterFrom.length > 0) {
      objects = objects.filter((object) => {
        return Date.parse(object.createdAt!) >= Date.parse(dateFilterFrom);
      });
    }

    if (dateFilterTo.length > 0) {
      const upToEndOfDay = 86399000;
      objects = objects.filter((object) => {
        return (
          Date.parse(object.createdAt!) <=
          Date.parse(dateFilterTo) + upToEndOfDay
        );
      });
    }

    return objects;
  }
}
