export class SalesReportSummary {
  transactionCount!: number;
  totalCollected!: number;
  netSales!: number;
  totalGrossSales!: number;
  totalDiscounts!: number;
  totalTaxs!: number;
  totalWholeSales!: number;
  totalProfit!: number;
}
