import Order from '@/Interfaces/Order';
import { TransactionType } from '@/enums/TransactionType';
import { PaymentType } from '@/enums/PaymentType';
import moment, { Moment } from 'moment';

export default class SalesReportSearchService {
  searchAndFilterOrders(
    orders: any[],
    searchTerm: string,
    dateFilterFrom: any,
    dateFilterTo: any,
  ) {
    if (searchTerm.length > 0) {
      const search = new RegExp(searchTerm, 'gi');
      orders = orders.filter((order) => {
        return (
          order.id.match(search) ||
          order.grossSale.toString().match(search) ||
          order.discountAmount.toString().match(search) ||
          order.subtotal.toString().match(search) ||
          order.tax.toString().match(search) ||
          order.total.toString().match(search) ||
          TransactionType[order.transactionType].match(search) ||
          PaymentType[order.paymentType].match(search) ||
          order.location.name.match(search)
        );
      });
    }

    if (dateFilterFrom.length > 0) {
      orders = orders.filter((order) => {
        return moment(order.createdAt!).isSameOrAfter(
          moment(dateFilterFrom).startOf('day'),
        );
      });
    }

    if (dateFilterTo.length > 0) {
      orders = orders.filter((order) => {
        return moment(order.createdAt!).isSameOrBefore(
          moment(dateFilterTo).endOf('day'),
        );
      });
    }

    return orders;
  }
}
