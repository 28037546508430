import Order from '../Interfaces/Order';
import ProductSalesReports from '@/Interfaces/ProductSalesReports';
import { ItemType } from '../enums/ItemType';
import { TransactionType } from '../enums/TransactionType';
import { PaymentType } from '../enums/PaymentType';
import Tax from '@/Interfaces/Tax';
import ReportCalculationService from '@/services/ReportCalculationService';

export default class ReportService {
  reportCalculationService = new ReportCalculationService();
  dateFilterFrom = '';
  dateFilterTo = '';
  getCsvFilename(prefix: string) {
    const today = new Date(Date.now());
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return `${prefix}-${month}-${day}-${year}.csv`;
  }
  // Sales Report

  getSalesCsvValues(orders: Order[]) {
    const snapshotData: any[] = [];
    orders.forEach((order) => {
      order.variantSnapshots.forEach((snapshot) => {
        const reportedOrder: any = {};
        reportedOrder['Transaction ID'] = order.id;
        reportedOrder['Date'] = this.getDate(order.createdAt!);
        reportedOrder['Time'] = this.getTime(order.createdAt!);
        reportedOrder['Item'] = snapshot.itemName;
        reportedOrder['Manufacturer'] = snapshot.itemManufacturer;
        reportedOrder['Variant'] = snapshot.variantName;
        reportedOrder['SKU'] = snapshot.variantSku;
        reportedOrder['UPC'] = snapshot.variantUpc;
        reportedOrder['Goods/Services'] = ItemType[snapshot.itemType!];
        reportedOrder['Qty'] = this.getQuantity(order);
        reportedOrder['Gross Sales'] = snapshot.variantPrice;
        reportedOrder['Discounts'] = snapshot.itemDiscount;
        reportedOrder['Net Sales'] = snapshot.variantDiscountedPrice;
        reportedOrder['Tax'] = snapshot.variantTaxedAmount;
        reportedOrder['Total Collected'] = snapshot.variantTotal;
        reportedOrder['Event Type'] = TransactionType[order.transactionType];
        reportedOrder['Payment Type'] = this.getPaymentType(order);
        reportedOrder['Location'] = order.location.name;
        reportedOrder['Customer Name'] = order.customer
          ? `${order.customer.first_name} ${order.customer.last_name}`
          : '';
        reportedOrder['Created By'] = order.createdBy!.email;
        snapshotData.push(reportedOrder);
      });
    });
    return snapshotData;
  }

  // Product Sales Report
  getProductSalesCsvValues(orders: ProductSalesReports[]) {
    const snapshotData: any[] = [];
    orders.forEach((snapshot) => {
      const reportedOrder: any = {};
      reportedOrder['Item Name'] = snapshot.Item_Name;
      reportedOrder['Manufacturer'] = snapshot.Manufacturer;
      reportedOrder['Variant'] = snapshot.Variant;
      reportedOrder['UPC'] = snapshot.UPC;
      reportedOrder['Price'] = snapshot.Price;
      reportedOrder['Location'] = snapshot.Location;
      reportedOrder['Quantity'] = snapshot.Quantity;
      reportedOrder['Total Sell'] = snapshot.Total;
      reportedOrder['Tax'] = snapshot.Tax;
      reportedOrder['NetSell'] = snapshot.NetSell;

      snapshotData.push(reportedOrder);
    });
    return snapshotData;
  }

  // Tax Report

  getTaxCsvValues(
    taxes: Tax[],
    orders: Order[],
    dateFilterFrom: string,
    dateFilterTo: string,
  ) {
    const snapshotData: any[] = [];
    const taxSummary =
      this.reportCalculationService.calculateTaxReportSummary(orders);
    taxes.forEach((tax) => {
      const reportedTax: any = {};
      reportedTax['Tax'] = tax.name;
      reportedTax['Taxable Amount'] =
        this.reportCalculationService
          .calculateTaxableAmountForTax(tax)
          .getAmount() / 100;
      reportedTax['Taxable Collected'] =
        this.reportCalculationService
          .calculateTaxCollectedForTax(tax)
          .getAmount() / 100;
      snapshotData.push(reportedTax);
    });
    const taxSummaryReport: any = {};
    const taxSummaryReportValues: any = {};
    taxSummaryReport['Tax'] = 'Taxable Sales';
    taxSummaryReport['Taxable Amount'] = 'Non-Taxable Sales';
    taxSummaryReport['Taxable Collected'] = 'Total Net Sales';
    taxSummaryReportValues['Tax'] = taxSummary.taxableSales;
    taxSummaryReportValues['Taxable Amount'] = taxSummary.nonTaxableSales;
    taxSummaryReportValues['Taxable Collected'] = taxSummary.totalNetSales;
    snapshotData.push(taxSummaryReport);
    snapshotData.push(taxSummaryReportValues);
    return snapshotData;
  }

  getDate(timestamp: Date) {
    return new Date(timestamp).toLocaleDateString();
  }

  getTime(timestamp: Date) {
    const date = new Date(timestamp);
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  getQuantity(order: Order) {
    if (
      order.transactionType === TransactionType.Sale ||
      order.transactionType === TransactionType.Declined
    )
      return 1;
    else if (
      order.transactionType === TransactionType.Refund ||
      order.transactionType === TransactionType.Void
    )
      return -1;
  }

  getPaymentType(order: Order) {
    if (order.paymentType === PaymentType.Cash) return 'Cash';
    if (order.paymentType === PaymentType.CreditCard) return 'Credit';
    else return '';
  }
}
