export default class ProductSalesReportSortService {
  sortTotal(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.Total);
    const totalB = parseFloat(b.Total);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }

  sortNetSell(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.NetSell);
    const totalB = parseFloat(b.NetSell);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }

  sortQuantity(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.Quantity);
    const totalB = parseFloat(b.Quantity);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }

  sortTax(a: any, b: any, isAsc: boolean) {
    const totalA = parseFloat(a.Tax);
    const totalB = parseFloat(b.Tax);

    if (totalA === null && totalB === null) return 0;

    if (isAsc) {
      if (totalA < totalB) return -1;
      if (totalA > totalB) return 1;
      if (totalB === null) return 1;
      if (totalA === null) return -1;
      else return 0;
    } else {
      if (totalA > totalB) return -1;
      if (totalA < totalB) return 1;
      if (totalB === null) return -1;
      if (totalA === null) return 1;
      else return 0;
    }
  }
}
